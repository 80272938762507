.divider {
  position: relative;
  width: var(--lineWidth);
  height: var(--lineHeight);
}

.line {
  width: 100%;
  height: 100%;
  background-color: rgb(var(--rgbPrimary));
  opacity: 1;
  transition-property: opacity;
  transition-duration: var(--durationL);
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-delay: var(--collapseDelay);
  transform-origin: left center;
  transform: scaleX(1);

  @media (--mediaUseMotion) {
    transition-property: transform, opacity;
  }

  &[data-collapsed='true'] {
    opacity: 0;
    transform: scaleX(0);
  }
}

.notch {
  background-color: rgb(var(--rgbPrimary));
  position: absolute;
  transition-property: opacity;
  transition-duration: var(--durationL);
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-delay: var(--collapseDelay);
  opacity: 1;
  clip-path: polygon(0 -1px, 100% -1px, calc(100% - 10px) 100%, 10px 100%);
  width: var(--notchWidth);
  height: var(--notchHeight);
  top: var(--lineHeight);

  @media (--mediaUseMotion) {
    transition-property: clip-path, opacity;
  }

  &[data-collapsed='true'] {
    opacity: 0;
    clip-path: polygon(0 -1px, 0 -1px, 10px 100%, 10px 100%);
  }
}
