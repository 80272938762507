.profile {
  width: 100vw;
  min-height: 100vh;
  margin-top: 60px;
  margin-bottom: 40px;
  padding-top: 60px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;

  @media (--mediaTablet) {
    padding-top: 50px;
    padding-right: 80px;
    padding-left: 160px;
    height: auto;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  @media (--mediaMobile) {
    margin-top: 0;
    padding-top: var(--space4XL);
    overflow-x: hidden;
  }

  @media (--mediaMobile), (--mediaMobileLS) {
    padding-right: var(--spaceOuter);
    padding-left: var(--spaceOuter);
  }

  @media (max-width: 820px) and (max-height: 420px) {
    padding-right: var(--space4XL);
    padding-left: var(--space4XL);
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr 50%;
  grid-column-gap: var(--space2XL);
  max-width: var(--maxWidthL);
  width: 100%;

  @media (--mediaTablet) {
    max-width: 600px;
    grid-template-columns: 100%;
  }
}

.column {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
  transform: translate3d(0, 0, 0);
}

.title {
  white-space: nowrap;
  margin-bottom: var(--spaceL);
  opacity: 0;
  transition: opacity var(--durationXL) ease var(--durationM);

  &[data-visible='true'] {
    opacity: 1;
  }
}

.description {
  margin-bottom: var(--spaceXL);
  opacity: 0;
  transition: opacity var(--durationXL) ease var(--durationL);

  &[data-visible='true'] {
    opacity: 1;
  }
}

.tag {
  margin-top: 220px;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: var(--space4XL) 1fr;
  gap: 12px;
  align-items: center;

  @media (--mediaTablet) {
    margin-top: 30px;
  }
}

.tagText {
  font-size: var(--fontSizeBodyS);
  font-weight: var(--fontWeightMedium);
  color: rgb(var(--rgbPrimary));
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationM);
  transition-delay: 1.3s;

  @media (--mediaUseMotion) {
    transition-property: opacity, transform;
    transform: translate3d(calc(var(--spaceM) * -1), 0, 0);
  }

  &[data-visible='true'] {
    transform: none;
    opacity: 1;
  }
}

.image {
  position: relative;
  width: 100%;
}

.svg {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate3d(50%, -20%, 0);
  height: 100%;
  z-index: var(--zIndex3);
  opacity: 0;
  transition: opacity var(--durationM) ease var(--durationL);
  fill: rgb(var(--rgbAccent));

  &[data-visible='true'] {
    opacity: 1;
  }
}

.button {
  opacity: 0;
  transition: opacity var(--durationXL) ease var(--durationL);

  &[data-visible='true'] {
    transform: none;
    opacity: 1;
  }
}
